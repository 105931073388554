<template>
     <div v-if="isLoggedIn == true" class="inevntory-variants container">
      <div v-if="loader">
        <ProgressSpinner id="loader" />
      </div>
      <div v-if="variants.length">
         <DataTable :value="variants" ref="dt" :rows="200"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            responsiveLayout="scroll"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            >
            <!-- <template #header>
                <div style="text-align: left">
                    <Button icon="pi pi-external-link" label="Export" @click="exportCSV($event)" />
                </div>
            </template> -->
            <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field"></Column>
        </DataTable>
        <div v-if="nextPageCheck" class="bottomBtn">
          <Button icon="pi" label="View More" @click="nextPages(nextPageCheck)" />
        </div>
      </div>
    </div>
    <div v-else class="error">
      Please login to check the page <a href="/">Login</a>
    </div>
    <div v-bind:class="(loader)?'loading':'loaded'"></div>
  </template>
  
  <script>
  import { mapActions,mapGetters } from "vuex";
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';
  import Button from 'primevue/button';
  import ProgressSpinner from 'primevue/progressspinner';
  export default {
    name: 'inevntory-variants',
     components: {
      DataTable,
      Column,
      Button,
      ProgressSpinner
     },
      data() {
        return {
          loader:true,
          variant_id:null,
          nextPageCheck:null,
          variants:[]
        }
     },
     created() {
      this.columns = [
            {field: 'product.legacyResourceId', header: 'Product ID'},
            {field: 'product.vendor', header: 'Vendor'},
            {field: 'displayName', header: 'Title'},
            {field: 'legacyResourceId', header: 'Variant ID'},
            {field: 'sku', header: 'Variant Sku'},
            {field: 'inventoryQuantity', header: 'Ineventory'},
            {field: 'metafield.legacyResourceId', header: 'Metafield Id'},
            {field: 'metafield.value', header: 'Metafield value'}
        ];
     },
      methods: {
           ...mapActions(["getInevntory"]),
           ...mapGetters(["isAuthenticated","userToken"]), 
        exportCSV() {
            this.$refs.dt.exportCSV();
        },
        nextPages:async function () {
          this.loader = true;
          document.body.scrollTop = document.documentElement.scrollTop = 0;
          var data = {'password':this.userToken,'pageKey':this.nextPageCheck}
          var updatedVariants = await this.getInevntory(data);
          this.variants = updatedVariants.data.variants;
          this.nextPageCheck =updatedVariants.data.nextPage;
          this.loader = false;
        }
      },
      mounted:async function () {
        if(this.isLoggedIn){
            var data = {'token':this.userToken,'pageKey':null}
            const getVariants =  await this.getInevntory(data);
            this.variants = getVariants.data.variants;
            if(getVariants.data.nextPage){
              this.nextPageCheck = getVariants.data.nextPage;
            }
            this.loader = false;
        }
      },
      computed: {
        isLoggedIn : function(){
            return this.$store.getters.isAuthenticated
        },
        userToken : function(){
          return this.$store.getters.getUserToken
        },
      },
  
  
  }
  </script>